
// Generated by common/web/keyman-version/build.sh
//
// Note:  does not use the 'default' keyword so that the export name is
// correct when converted to a CommonJS module with `esbuild`.
export class KEYMAN_VERSION {
  static readonly VERSION = "17.0.332";
  static readonly VERSION_RELEASE ="17.0";
  static readonly VERSION_MAJOR = "17";
  static readonly VERSION_MINOR = "0";
  static readonly VERSION_PATCH = "332";
  static readonly TIER ="stable";
  static readonly VERSION_TAG = "";
  static readonly VERSION_WITH_TAG = "17.0.332";
  static readonly VERSION_ENVIRONMENT = "stable";
  static readonly VERSION_GIT_TAG = "release@17.0.332";
}

// Also provides it as a 'default' export.
export default KEYMAN_VERSION;
  
